<template>
  <client-only>
    <BaseDropdown
      v-if="$features.orchestratorTest"
      :label="$t('Type')"
      class="my-2 mb-4 relative z-50"
      :options="jobType"
      :value="jobSelected"
      @select="setSelected"
    ></BaseDropdown>
    <h2 v-if="!$features.orchestratorTest || jobSelected !== 'orchestrator'" class="text-primary">
      {{ $t('Transkribus Server Jobs') }}
    </h2>

    <div class="flex justify-end" v-if="!$features.orchestratorTest || jobSelected !== 'orchestrator'">
      <NuxtLink
        :to="
          localePath({
            name: 'jobs',
          })
        "
      >
        <BaseButton
          :label="$t('Open Full Jobs Table >')"
          @click="openJobs"
          :type="'text'"
          small
          icon="chevron"
        />
      </NuxtLink>
    </div>

    <!-- Use JobTable component instead of BaseTable -->
    <div>
      <OrchestratorJobsTable
        v-if="$features.orchestratorTest && jobSelected === 'orchestrator'"
        ref="jobTable"
        @jobs-loaded="handleJobsLoaded"
      />
      <JobTable
        v-else
        :showSelectionColumn="false"
        :showTypeFilter="false"
        :filterByUser="filterByUser"
        :customColumns="columns"
        :status="status"
        :sortField="sortField"
        :sortOrder="sortOrder"
        :itemsPerPage="50"
        :serverType="serverType"
        :jobTypes="filterType ? [filterType] : undefined"
        :enableEndlessScrolling="false"
        :scrollContainer="'.app-slide-over'"
        @jobs-loaded="handleJobsLoaded"
        ref="jobTable"
      >
        <!-- Override any slot content if needed -->
      </JobTable>
    </div>
  </client-only>
</template>

<script>
import jobTypeConfig from '~/assets/job-types.json'
import { useApi } from '~/composables/api/useApi'
import JobTable from '~/components/Job/Table.vue'
import OrchestratorJobsTable from '~/components/Job/OrchestratorJobsTable.vue'

export default {
  components: {
    JobTable,
    OrchestratorJobsTable,
  },
  setup() {
    const jobsApi = useJobs()
    const runtimeConfig = useRuntimeConfig()
    const { fetchWithAuth } = useApi()

    return {
      jobsApi,
      runtimeConfig,
      fetchWithAuth,
    }
  },
  watch: {
    status(value) {
      if (this.$refs.jobTable) {
        this.$refs.jobTable.localStatus = value
      }
    },
    jobSelected(value) {
      this.getJobs()
    },
  },
  data() {
    return {
      jobs: [],
      jobOptions: [],
      isLoading: false,
      total: 12,
      polling: null,
      timeout: null,
      collId: null,
      filterType: null,
      status: undefined,
      filterByUser: true,
      sortField: 'jobId',
      sortOrder: 'desc',
      jobType: [
        { name: 'Transkribus Server', value: 'transkribus' },
        { name: 'Orchestrator', value: 'orchestrator' },
      ],
      jobSelected: this.jobSliderType || 'transkribus',
      columns: [
        {
          field: 'docTitle',
          label: this.$t('Title'),
          sortable: true,
          display: true,
          custom: true,
          slotName: 'title-field',
        },
        {
          field: 'jobImpl',
          label: this.$t('Job'),
          numeric: false,
          sortable: true,
          display: true,
          custom: true,
          slotName: 'jobtype-field',
        },
        {
          field: 'started',
          label: this.$t('Start'),
          sortable: true,
          display: true,
          custom: true,
          slotName: 'start-field',
        },

        {
          field: 'state',
          label: this.$t('State'),
          sortable: true,
          custom: true,
          display: true,
          slotName: 'state-field',
        },

        {
          field: 'action',
          label: this.$t('Action'),
          custom: true,
          display: true,
          slotName: 'action-field',
        },
      ],
    }
  },
  props: {
    jobSliderType: {
      type: String,
    },
  },
  mounted() {
    if (
      this.jobSliderType !== undefined &&
      this.jobSliderType !== null &&
      this.jobSliderType !== ''
    ) {
      this.setSelected(this.jobSliderType)
    }

    this.jobOptions = [...jobTypeConfig]
    this.$nextTick(() => {
      this.getJobs()
    })
    this.$bus.on('slideOver', () => {
      this.getJobs()
    })
  },
  beforeDestroy() {
    this.stopPolling()
    this.$bus.off('slideOver')
  },
  methods: {
    handleJobsLoaded(jobs) {
      this.jobs = jobs
      this.isLoading = false
    },
    async getJobs() {
      this.isLoading = true
    },
    setLoading(value) {
      this.$nextTick(() => (this.isLoading = value))
    },
    setSelected(value) {
      this.jobSelected = value
    },
    descriptionValue(value) {
      if (value === undefined) {
        return value
      }

      let typeFound = this.jobOptions.find(val => val.value === value)

      if (typeFound) {
        return typeFound.name
      } else return value
    },

    stopPolling() {
      clearInterval(this.polling)
      this.getJobs()
    },
    openJobs() {
      this.$bus.emit('slideOverClose')
    },
  },
  computed: {
    isAdmin() {
      const { userProfile } = useKeycloak()
      return userProfile?.IsAdmin
    },
    userId() {
      const { userProfile } = useKeycloak()
      return userProfile?.UserId
    },
  },
}
</script>
